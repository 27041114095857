/* App.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: visible;
  overflow: hidden;
  background-color: #000;

}

h1 {
  text-align: center;
  margin-top: 20px;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000da;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #10181a;
  padding: 20px;
  border-radius: 18px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 75%;
}

form input {
  background-color: #000;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
  height: 3rem;
  color: #fff;

}

form input::placeholder {
  color: #334246;
  font-size: 1.3em;
}

form button {
  width: 100%;
  background-color: #4229d1;
  font-weight:700;
  color: #000936;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1em;
  height: 3rem;
}

form button:hover {
  background-color: #150f3a;
}

