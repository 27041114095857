.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000000;
}

.loading-container-spinner {
    width: 6rem;
    height: auto;
}